html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-light-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-light-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-light-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-light-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-light-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-light-webfont.svg#open_sansregular") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: fallback; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-lightitalic-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-lightitalic-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-lightitalic-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-lightitalic-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-lightitalic-webfont.svg#open_sansregular") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: fallback; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-book-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-book-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-book-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-book-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-book-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-book-webfont.svg#open_sansregular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-bookitalic-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-bookitalic-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-bookitalic-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-bookitalic-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-bookitalic-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-bookitalic-webfont.svg#open_sansregular") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: fallback; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-medium-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-medium-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-medium-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-medium-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-medium-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-medium-webfont.svg#open_sansregular") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-mediumitalic-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-mediumitalic-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-mediumitalic-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-mediumitalic-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-mediumitalic-webfont.svg#open_sansregular") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: fallback; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-bold-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-bold-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-bold-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-bold-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-bold-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-bold-webfont.svg#open_sansregular") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: fallback; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-bolditalic-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-bolditalic-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-bolditalic-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-bolditalic-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-bolditalic-webfont.svg#open_sansregular") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: fallback; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-black-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-black-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-black-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-black-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-black-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-black-webfont.svg#open_sansregular") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: fallback; }

@font-face {
  font-family: 'Texta';
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-blackitalic-webfont.eot");
  src: url("~@massds/mayflower-react/es/assets/fonts/texta-blackitalic-webfont.eot?#iefix") format("embedded-opentype"), url("~@massds/mayflower-react/es/assets/fonts/texta-blackitalic-webfont.woff2") format("woff2"), url("~@massds/mayflower-react/es/assets/fonts/texta-blackitalic-webfont.woff") format("woff"), url("~@massds/mayflower-react/es/assets/fonts/texta-blackitalic-webfont.ttf") format("truetype"), url("~@massds/mayflower-react/es/assets/fonts/texta-blackitalic-webfont.svg#open_sansregular") format("svg");
  font-weight: 800;
  font-style: italic;
  font-display: fallback; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-size: 14.5px; }
  @media (min-width: 621px) {
    html {
      font-size: 16px; } }

body {
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.5;
  color: #141414;
  font-family: "Texta", "Helvetica", "Arial", "sans-serif";
  font-weight: 400; }

a {
  transition: all 0.4s ease;
  text-decoration: none;
  font-weight: 700;
  color: #14558f; }

img {
  height: auto;
  max-width: 100%; }

svg {
  overflow: hidden; }

ul, ol {
  margin-top: 0; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

ul ul,
ol ul {
  list-style-type: circle;
  padding-left: 30px;
  margin-bottom: 1em; }

ol {
  list-style-type: decimal; }
  ol ol {
    list-style-type: lower-alpha; }
    ol ol ol {
      list-style-type: lower-roman; }
      ol ol ol ol {
        list-style-type: decimal; }
        ol ol ol ol ol {
          list-style-type: lower-alpha; }
          ol ol ol ol ol ol {
            list-style-type: lower-roman; }
            ol ol ol ol ol ol ol {
              list-style-type: decimal; }
              ol ol ol ol ol ol ol ol {
                list-style-type: lower-alpha; }
                ol ol ol ol ol ol ol ol ol {
                  list-style-type: lower-roman; }
                  ol ol ol ol ol ol ol ol ol ol {
                    list-style-type: decimal; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: #141414;
  font-weight: 700; }

h1 {
  font-size: 3rem;
  line-height: 1.07; }
  @media (min-width: 781px) {
    h1 {
      font-size: 3.25rem; } }
  @media (min-width: 1201px) {
    h1 {
      font-size: 3.5rem; } }

h2 {
  font-size: 2.25rem;
  line-height: 1.2;
  margin-bottom: 1rem; }

h3 {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: .75em; }
  @media (min-width: 621px) {
    h3 {
      margin-bottom: 1em; } }

h4 {
  font-size: 1.813rem;
  line-height: 1.2; }

h5 {
  font-size: 1.625rem;
  line-height: 1.3;
  margin-bottom: .5em; }

h6 {
  font-size: 1.375rem;
  line-height: 1.4;
  margin-bottom: .25em; }

.ma__visually-hidden,
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.scroll-disabled {
  height: 100vh;
  overflow: hidden; }

h1[tabindex="-1"]:focus,
h2[tabindex="-1"]:focus,
h3[tabindex="-1"]:focus,
h4[tabindex="-1"]:focus,
p[tabindex="-1"]:focus,
main[tabindex="-1"]:focus,
div[tabindex="-1"]:focus {
  outline: none; }

b, strong {
  font-weight: 700; }

.pre-content::after,
.main-content::after,
.post-content::after {
  clear: both;
  content: "";
  display: block; }

.main-content {
  margin-bottom: 45px; }
  @media (min-width: 911px) {
    .main-content {
      margin-bottom: 60px; } }

.main-content--two {
  margin-right: auto;
  margin-left: auto;
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 781px) {
    .main-content--two {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 911px) {
    .main-content--two {
      padding-left: 40px;
      padding-right: 40px; } }

.page-content {
  position: relative; }
  @media (min-width: 911px) {
    .main-content--two .page-content {
      float: left;
      padding-right: 85px;
      width: calc(100% - 295px); } }
  @media (min-width: 1201px) {
    .main-content--two .page-content {
      padding-right: 125px; } }
  .page-content > :last-child {
    margin-bottom: 0; }
  .main-content--two .page-content > * {
    margin-top: 30px; }
    @media (min-width: 621px) {
      .main-content--two .page-content > * {
        margin-top: 45px; } }
    .main-content--two .page-content > *:first-child {
      margin-top: 0; }
  .main-content--full .page-content > * {
    margin-top: 40px; }
    @media (min-width: 621px) {
      .main-content--full .page-content > * {
        margin-top: 60px; } }
    @media (min-width: 781px) {
      .main-content--full .page-content > * {
        margin-top: 70px; } }
    @media (min-width: 911px) {
      .main-content--full .page-content > * {
        margin-top: 90px; } }
    .main-content--full .page-content > *:first-child {
      margin-top: 0; }

.post-content > * {
  margin-top: 30px; }
  @media (min-width: 621px) {
    .post-content > * {
      margin-top: 45px; } }
  .post-content > *:first-child {
    margin-top: 0; }

@media (min-width: 911px) {
  .sidebar {
    float: left;
    width: 295px; } }

.sidebar > * {
  margin-top: 40px; }
  @media (min-width: 621px) {
    .sidebar > * {
      margin-top: 65px; } }
  .sidebar > *:first-child {
    margin-top: 0; }

.page-content + .sidebar {
  padding-top: 40px; }
  @media (min-width: 621px) {
    .page-content + .sidebar {
      padding-top: 65px; } }
  @media (min-width: 911px) {
    .page-content + .sidebar {
      padding-top: 0; } }

.main-content--reversed .page-content {
  padding-bottom: 0;
  padding-right: 0; }
  @media (min-width: 911px) {
    .main-content--reversed .page-content {
      float: left;
      padding-left: 20px;
      width: calc(100% - 370px); } }
  @media (min-width: 1201px) {
    .main-content--reversed .page-content {
      padding-left: 50px; } }

.main-content--reversed .sidebar {
  padding-bottom: 45px; }
  @media (min-width: 911px) {
    .main-content--reversed .sidebar {
      float: left;
      padding-bottom: 0;
      width: 370px; } }

.stacked-row-content {
  position: relative; }

.ma__narrow-template--blue:before {
  background-color: rgba(20, 85, 143, 0.15); }

.ma__narrow-template--green:before {
  background-color: rgba(56, 133, 87, 0.15); }

.ma__narrow-template--yellow:before {
  background-color: rgba(246, 197, 27, 0.15); }

@media print {
  * {
    box-shadow: none !important; }
  html {
    font-size: 13px; }
  body {
    color: #141414 !important;
    -webkit-print-color-adjust: exact !important; }
  .ma__stacked-row-section .main-content .page-content > *,
  .main-content--two .page-content > *,
  .main-content--full .page-content > * {
    margin-top: 0; }
  .ma__stacked-row__section ~ .ma__stacked-row__section {
    border-width: 0; }
  .wrapperStyles {
    flex-direction: row; }
  .wrapperSplitStyles {
    display: flex;
    flex-direction: row;
    width: 100% h5;
      width-margin-top: 1rem;
      width-margin-bottom: 0;
      width-text-align: center; }
    .wrapperSplitStyles .wrapperStyles {
      margin-bottom: 0px; }
  .ma__page-header__content {
    padding: 20px 0; }
  .map-print {
    page-break-inside: avoid; }
  .ma__header__search,
  .ma__floating-action,
  .ma__content-eyebrow__tags,
  .ma__page-header__tags,
  .ma__footer,
  .ma__fixed-feedback-button,
  .post-content #feedback,
  .messages--error,
  *[aria-hidden="true"],
  .ma__download-link__icon,
  .ma__sidebar-widget__more,
  .ma__page-banner__icon,
  .ma__callout-time__icon,
  .ma__jump-links,
  .ma__header__nav,
  .ma__toc--overlay,
  .ma__page-flipper,
  .ma__callout-stats,
  .ma__illustrated-header__image,
  .ma__pagination,
  .ma__location-listing__filters,
  .ma__action-activities__image,
  .ma__action-activities__link,
  .ma__action-header__divider,
  .ma__action-finder__see-all-container,
  .ma__page-banner__icon,
  .ma__page-banner--small .ma__page-banner__icon,
  .ma__banner-carousel,
  .ma__quick-actions,
  .ma__wait-time,
  .ma__video {
    display: none !important; }
  a::after {
    content: " (" attr(href) ")";
    font-size: 0.8em;
    font-weight: normal;
    width: 100%;
    white-space: nowrap; }
  .ma__illustrated-header__content {
    width: 100%; }
  .ma__illustrated-header {
    margin-bottom: 0px; }
  .ma__colored-heading--blue {
    background-color: white;
    border-bottom: 2px solid #14558f; }
  .sources-section {
    padding-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    color: #141414;
    border-top: 1px solid #141414; }
    .sources-section .main-content--two {
      font-size: 13px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px; }
  .main-content--two {
    margin-top: 30px; }
  .is-front .ma__page-intro__sub-title,
  .is-front .ma__search-banner__form,
  .is-front .ma__search-banner__links,
  .is-front .ma__search-banner__image-credit {
    display: none !important; }
  .is-front .ma__page-title {
    color: #141414 !important; }
  .is-front .ma__search-banner {
    min-height: 0;
    padding: 0; }
  .is-front .ma__stacked-row-section:first-of-type {
    padding: 0;
    margin: 0; }
  .is-front .ma__stacked-row-section:last-child {
    padding-top: 0; }
  .is-front .ma__stacked-row-section {
    padding-top: 70px;
    border-width: 0; }
  .is-front .ma__stacked-row-section ~ .ma__stacked-row-section .ma__stacked-row-section__container::before {
    padding: 0 !important; }
  .is-front article img {
    display: none; }
  .ma__organization-navigation {
    display: none !important; }
  .sidebar--colored .ma__comp-heading {
    padding-left: 0;
    margin-left: 0;
    color: #141414 !important; }
  .ma__location-listing__results,
  .ma__location-listing__map {
    width: 100%;
    min-height: 0; }
  .ma__location-banner__map {
    float: none;
    width: 100%; }
  .ma__google-map {
    display: inline !important;
    width: 90vw !important;
    margin: 0 auto; }
  .ma__location-banner__image {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-print-color-adjust: exact; }
  .ma__relationship-indicators {
    display: none !important; }
  .ma__page-banner {
    margin-bottom: 0;
    margin-top: 0 !important;
    padding: 0;
    min-height: 0;
    height: auto; }
    .ma__page-banner.ma__page-banner--overlay {
      height: auto; }
    .ma__page-banner .ma__page-banner__container {
      min-height: 0; }
    .ma__page-banner .ma__page-banner__content-wrapper,
    .ma__page-banner .ma__page-banner__title,
    .ma__page-banner .ma__page-banner__description {
      width: 100%;
      color: #141414;
      padding: 0; }
    .ma__page-banner .ma__page-banner__image {
      display: none;
      height: 0;
      position: unset; }
    .ma__page-banner .ma__page-banner__content {
      padding: 0;
      margin: 0;
      position: relative;
      max-width: none;
      width: 100%;
      height: auto;
      top: 0;
      max-width: none !important;
      color: #141414; }
      .ma__page-banner .ma__page-banner__content::before {
        display: none; }
    .ma__page-banner::after {
      display: none !important; }
  .ma__page-banner--columns {
    min-height: 0 !important;
    margin: 0 !important;
    padding: 0 !important; }
    .ma__page-banner--columns .ma__page-banner__content {
      height: auto !important;
      top: auto !important; }
    .ma__page-banner--columns .ma__page-banner__container {
      min-height: 0 !important; }
    .ma__page-banner--columns .ma__page-banner__description {
      padding: 0 !important;
      float: none !important;
      width: 100% !important;
      max-width: none; }
  .ma__page-banner--large {
    height: auto !important;
    width: 100% !important;
    max-width: none;
    margin-right: 0 !important;
    margin-bottom: 20px; }
  .main-content {
    margin-bottom: 0; }
  .ma__comp-heading {
    margin-top: 20px; }
  img,
  .ma__google-map,
  .ma__google-map__map,
  .ma__mapped-locations,
  .ma__section-links,
  .ma__about-section-person,
  .ma__location-banner__image {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid !important;
    break-inside: avoid !important; }
  .ma__colored-heading,
  .ma__comp-heading {
    padding: 0 !important;
    color: #141414 !important; }
  .ma__rich-text,
  table {
    margin-bottom: 2rem; }
  .ma__footnote-list {
    margin: 2rem 0; }
  .ma__callout-link {
    border-width: 0 !important;
    break-inside: avoid;
    page-break-inside: avoid;
    width: 100% !important; }
    .ma__callout-link a {
      padding: 0 !important; }
  .ma__press-status {
    padding: 0; }
  .ma__action-finder__header {
    margin-bottom: 0; }
  .ma__action-finder__container {
    padding-bottom: 0; }
  .ma__contact-us--accordion {
    border-width: 0 !important; }
    .ma__contact-us--accordion .ma__contact-us--accordion__status {
      display: none; }
    .ma__contact-us--accordion .ma__contact-us__content {
      display: block !important; }
    .ma__contact-us--accordion .ma__contact-group {
      width: 100%;
      max-width: 100%;
      border-width: 0; }
  .ma__contact-group {
    padding: 0;
    margin: 0; }
  .ma__contact-list {
    margin-top: 2rem !important; }
  .ma__split-columns__column {
    padding: 0; }
  .ma__contact-us__expand {
    display: none; }
  .ma__action-step {
    border-width: 0; }
    .ma__action-step .ma__action-step__header {
      padding: 20px 0 0;
      color: #141414; }
    .ma__action-step .ma__action-step__icon {
      display: none; }
    .ma__action-step .ma__action-step__content {
      display: inline !important;
      border-width: 0;
      page-break-inside: auto; }
  .ma__steps-ordered__item {
    padding-left: 0 !important; }
    .ma__steps-ordered__item .ma__action-step__header::after {
      display: none; }
    .ma__steps-ordered__item::before {
      display: none; }
  .ma__suggested-pages .ma__suggested-pages__title {
    text-align: left;
    padding-top: 20px;
    margin-bottom: 0; }
  .ma__suggested-pages .ma__suggested-pages__items {
    display: inline; }
  .ma__suggested-pages .ma__suggested-pages__item--figure {
    width: 100%;
    padding: 0;
    border-width: 0;
    margin: 0;
    text-align: left; }
    .ma__suggested-pages .ma__suggested-pages__item--figure a,
    .ma__suggested-pages .ma__suggested-pages__item--figure .ma__image {
      display: none; }
    .ma__suggested-pages .ma__suggested-pages__item--figure .ma__decorative-link a {
      display: block !important; }
  .ma__suggested-pages .ma__suggested-pages__more {
    display: none; }
  .ma__toc--hierarchy .ma__toc--hierarchy__title::after {
    display: none; }
  .ma__toc--hierarchy .ma__toc--hierarchy__container {
    top: 0;
    border: none !important; }
  .ma__toc--hierarchy .ma__toc--hierarchy__accordion-content {
    display: block; }
  .ma__link-list__items {
    padding-left: 0 !important; }
    .ma__link-list__items .ma__link-list__item {
      width: 100%;
      flex-basis: 100% !important;
      display: block !important; }
      .ma__link-list__items .ma__link-list__item .ma__image-link__text {
        display: block;
        width: 100%; }
      .ma__link-list__items .ma__link-list__item img {
        display: none; }
  .ma__featured-item-mosaic__container {
    display: flex;
    flex-direction: column; }
  .ma__featured-item-mosaic__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: none; }
    .ma__featured-item-mosaic__column .ma__image {
      display: none; }
    .ma__featured-item-mosaic__column .ma__featured-item__title-container {
      background-color: transparent;
      color: #141414;
      position: relative;
      display: inline-block;
      width: 100%;
      height: auto;
      padding-left: 0;
      font-size: 1.4rem;
      page-break-inside: avoid !important;
      break-inside: avoid !important; }
    .ma__featured-item-mosaic__column .ma__featured-item:before,
    .ma__featured-item-mosaic__column .ma__featured-item--tall:before {
      display: none !important; }
  .ma__press-teaser__image span {
    padding: 0 !important; }
  .ma__event-listing__past,
  .ma__press-listing__more,
  .ma__link-list__toggle {
    display: none; }
  .ma__details__content {
    margin-left: 0; }
  .ma__callout-time__text {
    padding-left: 0 !important; }
  .ma__action-step--accordion {
    border-width: 0 !important; }
    .ma__action-step--accordion button::after {
      display: none; }
    .ma__action-step--accordion .ma__action-step__header {
      padding: 0 !important; }
  .ma__fixed-feedback-button {
    display: none; }
  .ma__details__content .ma__rich-text ul li {
    list-style-type: none;
    padding-left: 2em;
    text-indent: -2em; }
    .ma__details__content .ma__rich-text ul li::before {
      content: "\2610";
      margin-right: 5px;
      font-size: 2.5rem; } }

@media (max-width: 620px) {
  html {
    font-size: 14.5px; } }

body {
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.5;
  color: #141414;
  font-family: "Texta", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  -ms-overflow-style: none; }

a {
  color: #14558f;
  font-weight: 600;
  text-decoration: none; }

legend,
label {
  display: block;
  margin-bottom: 0.25em;
  padding: 0;
  font-weight: 500; }

.ma__container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px; }
  @media (min-width: 781px) {
    .ma__container {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 911px) {
    .ma__container {
      padding-left: 40px;
      padding-right: 40px; } }

@media print {
  .ma__filter-box {
    display: none; } }

.main-content--two .page-content .bubble {
  margin-bottom: 100px; }

@media (min-width: 1201px) {
  .main-content--two .page-content {
    padding-right: 50px; } }

@media (min-width: 911px) {
  .main-content--two .page-content {
    padding-right: 25px; } }

section {
  clear: both;
  margin-bottom: 1rem;
  display: block; }

sup.credit {
  vertical-align: super;
  font-size: 1.2rem;
  font-weight: 700; }

.ma__comp-heading {
  clear: both; }

@media (max-width: 1200px) {
  .sidebar {
    float: none;
    width: 100%;
    margin-bottom: 20px; } }

.sidebar-wide {
  width: 350px; }
  @media (max-width: 1200px) {
    .sidebar-wide {
      float: none;
      width: 100%;
      margin-bottom: 20px; } }

@media (max-width: 1200px) {
  .main-content--two .page-content {
    width: 100%; } }

.main-content--two .page-content-narrow {
  width: calc(100% - 350px); }
  @media (max-width: 1200px) {
    .main-content--two .page-content-narrow {
      width: 100%; } }

.new {
  background-color: #66BD63; }

.old {
  background-color: #FDAE61; }

.main-content--two .page-content > * {
  margin-top: 0; }

.instruction {
  font-size: 1.2rem; }

.ma__decorative-link {
  display: inline; }

.main-content.sources-section {
  margin-bottom: 0px; }

.ma__button--quaternary {
  background-color: white; }

marker {
  background-color: #ffff00; }
