html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.wrapperStyles {
  width: 100%;
  max-width: 980;
  margin: 0px auto 50px;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .wrapperStyles.row {
    flex-direction: column; }
  @media (max-width: 910px) {
    .wrapperStyles {
      flex-direction: column; } }

.wrapperSplitStyles {
  display: flex;
  flex-direction: row;
  width: 100%; }
  @media (max-width: 910px) {
    .wrapperSplitStyles {
      flex-direction: column; } }
  .wrapperSplitStyles .wrapperStyles {
    margin-bottom: 0px; }

.wrapperSplitMap {
  width: 100%; }

.wrapperMapStyles {
  width: 100%;
  margin: 0; }

.legendTitle {
  font-size: 0.9rem;
  font-weight: 700; }

.vx-legend-item {
  line-height: 1.25;
  font-size: 1rem; }

.wrapperButtonStyles {
  width: 100%;
  max-width: 980;
  margin: 0px auto 10px;
  text-align: center; }

.wrapperLegendStyles {
  width: 100%;
  max-width: 200px;
  margin: 0px auto 10px;
  padding-top: 5%;
  justify-content: center;
  align-items: center;
  padding-left: 10px; }
  .wrapperLegendStyles .vx-legend-item {
    font-size: 0.8rem; }

.wrapperLegendStyles.row {
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  padding: 0px;
  margin: 0px; }
  .wrapperLegendStyles.row .vx-legend {
    display: flex;
    flex-wrap: wrap; }
    .wrapperLegendStyles.row .vx-legend .vx-legend-item {
      min-width: 80px;
      flex: 1 1 0%; }

.map-control-wrapper {
  width: 100%; }
  @media (min-width: 911px) {
    .map-control-wrapper {
      display: flex;
      justify-content: space-between; }
      .map-control-wrapper .input-text-container {
        width: calc(100% - 200px);
        max-width: 800px; }
      .map-control-wrapper .button-zoom-container {
        margin-top: 30px;
        margin-left: 10px; } }

.ma__button, .ma__button--minor {
  padding: 5px 10px;
  margin: 5px;
  text-transform: none;
  letter-spacing: 0.05em;
  font-size: 1.1rem; }
  @media (max-width: 1200px) {
    .ma__button, .ma__button--minor {
      padding: 2px 5px;
      margin: 2px;
      font-size: 1rem; } }

.ma__button--quaternary {
  font-size: 1rem !important; }
  .ma__button--quaternary.inactive {
    color: #535353;
    background-color: none;
    box-shadow: none;
    border-bottom: 2px solid #DCDCDC; }
    .ma__button--quaternary.inactive:hover {
      color: #14558f;
      border-bottom: 2px solid #8AAAC7; }

.button-zoom {
  margin-top: 1rem;
  margin-right: 0.2rem;
  background-color: none;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  font-weight: 700;
  color: #388557; }
  .button-zoom svg {
    fill: #388557; }
  @media (min-width: 911px) {
    .button-zoom-container {
      margin-top: 0;
      display: inline-block; } }

.tooltip-custom {
  bottom: 0px;
  left: 10px;
  position: absolute;
  font-size: 1.2rem; }

.map-title {
  font-size: 1.1rem;
  line-height: 1.2;
  height: 40px;
  margin-top: 10px; }
